import { GET_ONBOARDING_DATA } from "../types";
import { DGTL_BACKEND_URL } from "../URL";
import toast from "react-hot-toast";
const token =
  window.location.href.split("/")[5]?.split("&")[0] ||
  localStorage.getItem("token");

export const GetOnboardingData = (formId) => (dispatch) => {
  if (!token) {
    toast.error("Token not found. Please log in.");
    return;
  }
  fetch(`${DGTL_BACKEND_URL}/admin/webhook/get-saved-form/${formId}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((res) => {
      dispatch({
        type: GET_ONBOARDING_DATA,
        payload: res?.data,
      });
    })
    .catch((error) => {
      console.log(error);
      if (error?.response?.data?.error) {
        // toast.error(error?.response?.data?.error?.message);
      } else if (error.request) {
        // toast.error(error?.request);
      } else {
        // toast.error(error?.message);
      }
    });
};
