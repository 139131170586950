// reducers/index.js
import { combineReducers } from "redux";
import userReducer from "./userReducer";
import kycReducer from "./kycReducer";
import tabReducer from "./tabReducer";
import scoreReducer from "./scoreReducer";
import calculateRiskReducer from "./calculateRiskReducers";
import riskTableReducer from "./riskTableReducer";
import onboardingReducer from "./onboardingReducer";

const rootReducer = combineReducers({
  user: userReducer,
  kyc: kycReducer,
  tab: tabReducer,
  score: scoreReducer,
  calculateRiskReducer: calculateRiskReducer,
  riskTable: riskTableReducer,
  onboardings: onboardingReducer,
});

export default rootReducer;
