import React, { useEffect } from "react";
//import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import logo from "./KYC.png";

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
`;

const Logo = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
  animation: rotate ${({ duration }) => duration} linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingBarWrapper = styled.div`
  width: 30%;
  background-color: #f3f3f3;
  border-radius: 4px;
  overflow: hidden;
`;

const LoadingBar = styled.div`
  height: 4px;
  width: 0;
  background-color: #4caf50;
  animation: loading 30s linear forwards;

  @keyframes loading {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`;

const Loader = ({ onLoaded, duration = "9s" }) => {
  useEffect(() => {
    const timer = setTimeout(onLoaded, 7000); // Show loader for 7 seconds
    return () => clearTimeout(timer);
  }, [onLoaded]);

  return (
    <LoaderWrapper>
      {/* <Logo src={logo} alt="Company Logo" duration={duration} /> */}
      <img src="/imgs/dgtl_logo.webp" className="mb-5" />
      <LoadingBarWrapper>
        <LoadingBar />
      </LoadingBarWrapper>
    </LoaderWrapper>
  );
};

export default Loader;
