// export const DGTL_BACKEND_URL = "http://localhost:5000";
//commented
let envrioment =
  window.location.href.split("&env=")[1] || localStorage.getItem("envrioment");
export let DGTL_BACKEND_URL = "";
export const RISK_ENGINE_BACKEND_URL = "https://risk.dgtlbanking.com/api";
if (envrioment == "staging") {
  DGTL_BACKEND_URL = "https://staging.digitaldeposits.app";
} else if (envrioment == "clients") {
  DGTL_BACKEND_URL = "https://clients.digitaldeposits.app";
} else if (envrioment == "local") {
  DGTL_BACKEND_URL = "http://localhost:8000";
} else if (envrioment == "cbl") {
  DGTL_BACKEND_URL = "https://dgtl.concordebb.com";
}
// export const DGTL_BACKEND_URL = "https://clients.digitaldeposits.app";
// export const DGTL_BACKEND_URL = "http://localhost:5000";
// export const DGTL_BACKEND_URL = "https://risk-engine-be.vercel.app";

export const REACT_APP_RISK_ENGINE_URL = "http://localhost:3001";
