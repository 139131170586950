import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResults } from "../store/actions/riskTableActions";
import { GetOnboardingData } from "../store/actions/onboardingActions";
import { countries } from "countries-list";
import { partial_ratio } from "fuzzball";

export default function OnboardingResult() {
  const threshold = 80;
  const dispatch = useDispatch();
  const apiData = useSelector((state) => state.riskTable);
  const { onboardingData } = useSelector((state) => state.onboardings);

  function matchField(field, value) {
    return (
      partial_ratio(field?.toLowerCase(), value?.toLowerCase()) >= threshold
    );
  }
  const FormId = localStorage.getItem("FormID");
  const token = localStorage.getItem("token");

  const listOfCountries = useMemo(() => {
    const countryList = [];
    for (let code in countries) {
      countryList.push({
        code,
        name: countries[code].name,
        fullName: `${code} - ${countries[code].name}`,
      });
    }
    return countryList.sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  useEffect(() => {
    if (!apiData?.riskTableData) {
      dispatch(getResults(FormId, "onboarding"));
    }
  }, [dispatch, FormId]);

  useEffect(() => {
    if (!onboardingData && FormId) {
      dispatch(GetOnboardingData(FormId));
    }
  }, [dispatch, onboardingData, FormId]);

  // Utility functions
  const getRiskValue = (risk) =>
    risk <= 2 ? "Low" : risk <= 4 ? "Medium" : risk >= 5 ? "High" : "";

  const getColorCode = (risk) =>
    risk <= 2
      ? "#23d423"
      : risk <= 4
      ? "orange"
      : risk >= 5
      ? "#ff4444"
      : "#b8afaf";

  const getRiskObj = useMemo(() => {
    const results = apiData?.riskTableData?.detailedResults || [];

    return (name) => results.find((item) => item.name === name) || {};
  }, [apiData]);

  const getDataValue = (keyword) => {
    if (
      matchField(keyword, "permanentresident") ||
      matchField(keyword, "entityincorporated") ||
      matchField(keyword, "countryofoperation") ||
      keyword == "countryofnationlity"
    ) {
      if (keyword == "countryofnationlity") {
        return (
          listOfCountries.find(
            (item) => item.code === onboardingData?.CountryCode
          )?.name || "N/A"
        );
      } else {
        let country =
          onboardingData?.data?.find((data) => matchField(data?.field, keyword))
            ?.value || "";

        return (
          listOfCountries.find((item) => item.code === country)?.name || country
        );
      }
    } else {
      return (
        onboardingData?.data?.find((data) => matchField(data?.field, keyword))
          ?.value || ""
      );
    }
  };
  const getQuestions = (keyword) => {
    return (
      onboardingData?.data?.find((data) => matchField(data?.field, keyword))
        ?.title || ""
    );
  };

  function calculateRisk() {
    // Calculate the sum of risk levels
    const totalRiskLevel = apiData?.riskTableData?.detailedResults?.reduce(
      (sum, item) => sum + item.riskLevel,
      0
    );

    // Determine the risk category
    let category;
    if (onboardingData?.data?.[0]?.value == "INDIVIDUAL") {
      if (totalRiskLevel <= 10) {
        category = "Low";
      } else if (totalRiskLevel <= 18) {
        category = "Medium";
      } else if (totalRiskLevel <= 25) {
        category = "High";
      }
    } else {
      if (totalRiskLevel <= 12) {
        category = "Low";
      } else if (totalRiskLevel <= 20) {
        category = "Medium";
      } else if (totalRiskLevel <= 30) {
        category = "High";
      }
    }

    // Return the result
    return (
      <p>
        <span className="verfication-msg">Results : Total Risk Score</span> is{" "}
        <span className="verfication-msg">{totalRiskLevel}</span>. Recommended{" "}
        <span className="verfication-msg">Risk Classification</span> is{" "}
        <span className="verfication-msg">{category}</span>
      </p>
    );
  }
  // Row renderer
  console.log("results", apiData?.riskTableData?.detailedResults);
  const renderRow = (keyword, riskName) => {
    const riskObj = getRiskObj(riskName);

    console.log("riskObj", riskObj);
    console.log("riskName", riskName);

    return (
      <tr>
        <th className="text-left">
          {keyword == "countryofnationlity"
            ? "Country of Nationality"
            : getQuestions(keyword) || "-"}
        </th>
        <td className="text-center">{getDataValue(keyword) || "-"}</td>
        <td
          style={{ background: getColorCode(riskObj.riskLevel) }}
          className="text-center"
        >
          {getRiskValue(riskObj.riskLevel)}
        </td>
        <td className="text-center">{riskObj.riskLevel || "-"}</td>
        <td className="text-center">{riskObj.title || "-"}</td>
      </tr>
    );
  };

  return (
    <>
      <div style={{ margin: "50px" }}>
        <h2 className="py-3 page-title mb-0">Risk Results</h2>
      </div>

      <div
        style={{
          padding: "50px",
        }}
      >
        <div className="">
          <h2 style={{ background: "none" }} className="py-3 page-title mb-0">
            {"Risk Category | Pre-assessment"}
          </h2>
        </div>
        <table width="100%" border="1" className="tablerisk mt-3 w-100">
          <thead>
            <tr>
              <th></th>
              <th colSpan="7" className="text-white" bgColor="#0b57d0">
                Risk Results Table
              </th>
            </tr>
          </thead>
          <thead>
            <tr className="bg-grey-10">
              <th style={{ height: "42" }} className="text-left">
                Pre-Screening Questions
              </th>
              <th className="text-center">Value</th>
              <th className="text-center">Risk</th>
              <th className="text-center">Risk Factor Rating (RFR) 1-5</th>
              <th className="text-center">RF Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="text-left">
                {"Please verify the spelling of your name"}
              </th>
              <td className="text-center">
                {`${onboardingData?.firstName || ""} ${
                  onboardingData?.lastName || ""
                }`}
              </td>
              <td
                style={{ background: "#b8afaf" }}
                className="text-center"
              ></td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
            </tr>
            <tr>
              <th className="text-left">{"What is your date of Birth?"}</th>
              <td className="text-center">{onboardingData?.Dob || "-"}</td>
              <td
                style={{ background: "#b8afaf" }}
                className="text-center"
              ></td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
            </tr>
            {onboardingData?.data?.[0]?.value == "INDIVIDUAL"
              ? renderRow("permanentlyreside", "Country of Residence")
              : renderRow("entityincorporated", "Country of Residence")}
            {renderRow("countryofoperation", "Country of Operation")}
            {renderRow("countryofnationlity", "Country of Nationality")}

            {renderRow(
              "industrydoyouoperate",
              "Source of Income from Industry"
            )}
            {renderRow("politicallyexposed", "Account Holder Types")}
            {renderRow("investmentservices", "Product and Service Types")}
          </tbody>
        </table>
        <div className="mt-4 mb-4">
          <span
            style={{
              fontSize: "17px",
            }}
          >
            {calculateRisk()}
          </span>
        </div>
      </div>
    </>
  );
}
