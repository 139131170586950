// reducer.js

import { GET_ONBOARDING_DATA } from "../types";

const initialState = {
  onboardingData: null,
  loading: false,
  error: null,
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONBOARDING_DATA:
      return {
        ...state,
        onboardingData: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default onboardingReducer;
