import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResults } from "../store/actions/riskTableActions";
import { GetOnboardingData } from "../store/actions/onboardingActions";
import { countries } from "countries-list";
import { useNavigate } from "react-router-dom";
export default function UnsavedInvestmentResult({
  showUnsavedResults,
  setShowUnsavedResults,
  apiData,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { onboardingData } = useSelector((state) => state.onboardings);

  const FormId = localStorage.getItem("FormID");
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!onboardingData && FormId) {
      dispatch(GetOnboardingData(FormId));
    }
  }, [dispatch, onboardingData, FormId]);

  // Utility functions
  console.log("apiData", apiData);

  const calculateRiskProfile = (toleranceScore, capacityScore) => {
    const table = [
      // Each row represents a tolerance range
      [
        "Conservative",
        "Conservative",
        "Conservative",
        "Conservative",
        "Conservative",
      ], // 0-2
      [
        "Conservative",
        "Conservative",
        "Moderately Conservative",
        "Moderately Conservative",
        "Moderately Conservative",
      ], // 3-5
      ["Conservative", "Conservative", "Moderate", "Moderate", "Moderate"], // 6-8
      [
        "Conservative",
        "Moderately Conservative",
        "Moderate",
        "Moderate",
        "Moderately Aggressive",
      ], // 9-11
      [
        "Conservative",
        "Moderately Conservative",
        "Moderate",
        "Moderately Aggressive",
        "Aggressive",
      ], // 12-15
    ];

    // Map scores to row indices
    const toleranceRanges = [
      [0, 2],
      [3, 5],
      [6, 8],
      [9, 11],
      [12, 15],
    ];
    const capacityRanges = [
      [0, 1],
      [2, 4],
      [5, 7],
      [8, 10],
      [11, 12],
    ];

    const getIndex = (score, ranges) =>
      ranges.findIndex(([min, max]) => score >= min && score <= max);

    const toleranceIndex = getIndex(toleranceScore, toleranceRanges);
    const capacityIndex = getIndex(capacityScore, capacityRanges);

    if (toleranceIndex === -1 || capacityIndex === -1) {
      return "Invalid scores";
    }

    return table[toleranceIndex][capacityIndex];
  };

  // Example usage
  const toleranceScore = apiData?.Tolerance?.totalScore || 0;
  const capacityScore = apiData?.Capacity?.totalScore || 0;

  const riskProfile = calculateRiskProfile(toleranceScore, capacityScore);

  return (
    <>
      <div className="col-lg-12 mt-2">
        <h2 className="py-3 page-title mb-0">Risk Resuts</h2>
      </div>

      <div
        style={{
          padding: "120px 140px",
        }}
      >
        <div className="col-lg-12 mt-4">
          <h2 className="py-3 page-title mb-0">
            {"Risk Category - Investment"}
          </h2>
        </div>
        <div className="mt-3">
          <span
            onClick={() => {
              setShowUnsavedResults(!showUnsavedResults);
              navigate("/investment-details");
            }}
            className=" text-end text-[14px] link-back"
          >
            Re Calculate
          </span>
        </div>
        <table width="100%" border="1" className="tablerisk w-100">
          <thead>
            <tr>
              <th></th>
              <th colSpan="7" className="text-white" bgColor="#0b57d0">
                Risk Results Table
              </th>
            </tr>
          </thead>
          <thead>
            <tr className="bg-grey-10">
              <th className="text-center">Willingness Score</th>
              <th className="text-center">Capacity 0-1</th>
              <th className="text-center">Capacity 2-4</th>
              <th className="text-center">Capacity 5-7</th>
              <th className="text-center">Capacity 8-10</th>
              <th className="text-center">Capacity 11-12</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="text-center">0-2</th>
              <td className="text-center">Conservative</td>
              <td className="text-center">Conservative</td>
              <td className="text-center">Conservative</td>
              <td className="text-center">Conservative</td>
              <td className="text-center">Conservative</td>
            </tr>
            <tr>
              <th className="text-center">3-5</th>
              <td className="text-center">Conservative</td>
              <td className="text-center">Conservative</td>
              <td className="text-center">Moderately Conservative</td>
              <td className="text-center">Moderately Conservative</td>
              <td className="text-center">Moderately Conservative</td>
            </tr>
            <tr>
              <th className="text-center">6-8</th>
              <td className="text-center">Conservative</td>
              <td className="text-center">Conservative</td>
              <td className="text-center">Moderate</td>
              <td className="text-center">Moderate</td>
              <td className="text-center">Moderate</td>
            </tr>
            <tr>
              <th className="text-center">9-11</th>
              <td className="text-center">Conservative</td>
              <td className="text-center">Moderately Conservative</td>
              <td className="text-center">Moderate</td>
              <td className="text-center">Moderate</td>
              <td className="text-center">Moderately Aggressive</td>
            </tr>
            <tr>
              <th className="text-center">12-15</th>
              <td className="text-center">Conservative</td>
              <td className="text-center">Moderately Conservative</td>
              <td className="text-center">Moderate</td>
              <td className="text-center">Moderately Aggressive</td>
              <td className="text-center">Aggressive</td>
            </tr>
          </tbody>
        </table>
        <div className="mt-3" style={{ marginLeft: "15px" }}>
          <span style={{ fontSize: "18px" }}>Results : </span>{" "}
          <span style={{ fontSize: "18px" }}>
            Willingness Score is {apiData?.Tolerance?.totalScore} and Capacity
            Score is {apiData?.Capacity?.totalScore} with a Total Score of{" "}
            {parseInt(apiData?.Tolerance?.totalScore) +
              parseInt(apiData?.Capacity?.totalScore)}{" "}
            and a Risk Category of {riskProfile}
          </span>
        </div>
      </div>
    </>
  );
}
