import React, { useState, useEffect } from "react";
import "./risktable.css";
import Tabs from "./components/RiskTable/table";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { getResults } from "./store/actions/riskTableActions";
import { fetchKycDetailRequest } from "./store/actions/kycActions";

import { useDispatch } from "react-redux";
import { BeatLoader } from "react-spinners";
import warning from "./images/warning.png";

const RiskTable = () => {
  const dispatch = useDispatch();
  const [currentTab, setcurrentTab] = useState("Show All");
  const apiData = useSelector((state) => state.riskTable);
  const userInfo = useSelector((state) => state.user);
  const { kycDetail } = useSelector((state) => state.kyc);
  const [riskArr, setNewriskArr] = useState([]);
  const [riskCategory, setRiskCategory] = useState([]);
  const navigate = useNavigate();
  let kycId = localStorage.getItem("kycID");
  let token = localStorage.getItem("token");

  useEffect(() => {
    if (!apiData?.riskTableData && token && !kycDetail) {
      dispatch(fetchKycDetailRequest(kycId));
      dispatch(getResults(kycId, "kyc"));
    }
  }, [dispatch, apiData]);

  function getHighestScoreObject(array) {
    // Return null if array is empty or invalid
    if (!array || array.length === 0) return null;

    // Use reduce to find object with highest score
    return array.reduce((max, current) => {
      return current.score > max.score ? current : max;
    }, array[0]);
  }

  useEffect(() => {
    if (apiData?.riskTableData) {
      // Map the adjusted data
      const adjustedData = apiData.riskTableData.detailedResults.map((e) => {
        return {
          calculatedRisk: e.calculatedRisk,
          name: e.name,
          risk: e.risk,
          title: e.title,
          riskLevel: Number(e.riskLevel),
          weight: Number(e.weight),
          riskrating: Number(e.riskLevel) * Number(e.weight),
          Sample: "kyc data",
          DataSource: e.DataSource,
          datasrc: null,
        };
      });

      // Set the state for riskArr
      setNewriskArr(adjustedData);

      // Create the risk object
      const riskObject = {
        impact: apiData.riskTableData.impact,
        overallRisk: apiData.riskTableData.overallRisk,
        probability: apiData.riskTableData.probability,
        riskCategory: apiData.riskTableData.riskCategory,
        totalRisk: apiData.riskTableData.totalRisk,
      };

      // Set the state for riskCategory
      setRiskCategory(riskObject);
    }
  }, [apiData]);

  useEffect(() => {
    if (!apiData?.riskTableData && token) {
      // Fetch data if not available
      dispatch(getResults(kycId, "kyc"));
    }
  }, [dispatch, apiData, token]);

  const HandleTabs = (val) => {
    setcurrentTab(val);

    if (val === "Analysis" || val === "Risk Mitigation") return;
    console.log(val);
    // setcurrentTab(val);

    if (val === "Risks Identified") {
      // Filter risk results where risk level is 3 or greater
      let riskgreater = riskArr && riskArr.filter((e) => e.riskLevel >= 3);
      setNewriskArr(riskgreater);
    } else if (val === "Impact") {
      return;
    } else {
      const adjustedData = apiData.riskTableData.detailedResults.map((e) => {
        return {
          calculatedRisk: e.calculatedRisk,
          name: e.name,
          risk: e.risk,
          title: e.title,
          riskLevel: Number(e.riskLevel),
          weight: Number(e.weight),
          riskrating: Number(e.riskLevel) * Number(e.weight),
          Sample: "kyc data",
          DataSource: e.DataSource,
          datasrc: null,
        };
      });

      // Set the state for riskArr
      setNewriskArr(adjustedData);
    }
  };
  function checkKYCValue(title) {
    if (title === "Country of Nationality") {
      return kycDetail?.countryOfBirth;
    }
    if (title === "Country of Operation") {
      return kycDetail?.countryOfResidence;
    }
    if (title === "Country of Residence") {
      return kycDetail?.countryOfResidence;
    }
    if (title === "Source of Income Legal Structure") {
      return kycDetail?.SourceOfIncomeLegalStructure?.title;
    }
    if (title === "Other Source of Income") {
      const SourceofIncomeObject = getHighestScoreObject(
        kycDetail?.OtherSourcesOfIncome
      );
      return SourceofIncomeObject?.title;
    }
    if (title === "Other Sources of Wealth") {
      const SourceofWealthObject = getHighestScoreObject(
        kycDetail?.OtherSourcesOfIncome
      );
      return SourceofWealthObject?.title;
    }
    if (title === "Source of Income from Industry") {
      return kycDetail?.SourceOfIncomeLegalStructure?.title;
    }
    if (title === "Account Holder Types") {
      return kycDetail?.AccountHolderType?.title;
    }
    if (title === "Country of Jurisdiction") {
      return kycDetail?.jurisdictionComingFrom?.[0];
    }
    if (title === "Purpose of Account") {
      return kycDetail?.PurposeOfAccount?.title;
    }
    if (title === "Product and Service Types") {
      return kycDetail?.ProductType?.reduce((highest, current) => {
        return current.score > highest.score ? current?.title : highest?.title;
      });
    }
    if (title === "Risk Level for Identity") {
      return kycDetail?.idType1;
    }
    if (title === "Risk Level for ID Verification") {
      return kycDetail?.idType2;
    }
    if (title === "Risk Level for Proof of Address") {
      return kycDetail?.proofOfAddressType;
    }
    if (title === "Risk Level for Source of Income Proof") {
      return kycDetail?.proofOfIncomeType;
    } else {
      return "N/A";
    }
  }

  function checkDataSource(title, dataSource) {
    if (title === "Country of Nationality") {
      return "KYC";
    }
    if (title === "Country of Operation") {
      return "KYC";
    }

    if (title === "Country of Residence") {
      return "KYC";
    }
    if (title === "Source of Income Legal Structure") {
      return "KYC";
    }
    if (title === "Other Source of Income") {
      return "KYC";
    }
    if (title === "Source of Income from Industry") {
      return "KYC";
    }
    if (title === "Risk Level for Identity") {
      return "KYC";
    }
    if (title === "Risk Level for ID Verification") {
      return "KYC";
    }
    if (title === "Account Holder Types") {
      return "KYC";
    }
    if (title === "Purpose of Account") {
      return "KYC";
    }
    if (title === "Product and Service Types") {
      return "KYC";
    }
    if (title === "Risk Level for Proof of Address") {
      return "KYC";
    }
    if (title === "Risk Level for Source of Income Proof") {
      return "KYC";
    }
    if (title === "Geographic Location") {
      return "KYC";
    } else {
      return dataSource;
    }
  }

  function cleanMessage(message) {
    // Remove the ```html\n part and any leading/trailing whitespace
    return message.replace(/^```html\s*\n|\s*```$/g, "");
  }

  const riskCategoryTable = [
    {
      category: "Insignificant",
      score:
        apiData.riskTableData?.riskCategory === "Insignificant"
          ? apiData.riskTableData?.overallRisk.toFixed(2)
          : "-",
      colorCode: "green",
      minScore: 5,
      maxScore: 8,
    },
    {
      category: "Minor",
      score:
        apiData.riskTableData?.riskCategory === "Minor"
          ? apiData.riskTableData?.overallRisk.toFixed(2)
          : "-",
      colorCode: "yellow",
      minScore: 8,
      maxScore: 10,
    },
    {
      category: "Significant",
      score:
        apiData.riskTableData?.riskCategory === "Significant"
          ? apiData.riskTableData?.overallRisk.toFixed(2)
          : "-",
      colorCode: "orange",
      minScore: 10,
      maxScore: 15,
    },
    {
      category: "Critical",
      score:
        apiData.riskTableData?.riskCategory === "Critical"
          ? apiData.riskTableData?.overallRisk.toFixed(2)
          : "-",
      colorCode: "red",
      minScore: 15,
      maxScore: 20,
    },
    {
      category: "Catastrophic",
      score:
        apiData.riskTableData?.riskCategory === "Catastrophic"
          ? apiData.riskTableData?.overallRisk.toFixed(2)
          : "-",
      colorCode: "black",
      minScore: 20,
      maxScore: 25,
    },
  ];

  return (
    <>
      {kycDetail ? (
        <>
          <Tabs HandleTabs={HandleTabs} />
          <section className="container py-3">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="py-3 page-title mb-0">
                  {currentTab === "Impact"
                    ? "Risk Category - Impact - Probability"
                    : "Risk Result"}
                </h2>
              </div>
              <div className="d-flex justify-content-between">
                {userInfo?.user?.accountType === "admin" ? (
                  <span
                    className="my-3 text-center text-[14px] link-back"
                    onClick={() => navigate("/dashboard")}
                  >
                    &larr; Risk Engine Settings
                  </span>
                ) : (
                  <div></div>
                )}

                <span
                  className="my-3 text-center text-[14px] link-back"
                  onClick={() => navigate("/details")}
                >
                  Re-evaluate User
                </span>
              </div>
            </div>
            {currentTab == "Analysis" ? (
              <div className="Analysis-box">
                <h3 className="analysis-report-heading">
                  Analysis Report Results
                </h3>
                <span
                  className="analysis-report-message"
                  dangerouslySetInnerHTML={{
                    __html: cleanMessage(
                      apiData?.riskTableData?.Analysismessage
                    ),
                  }}
                ></span>
                {/* <div className="mt-4">
              {apiData?.riskTableData?.highRiskItems?.map((d, i) => (
                <li className="analysis-risk-items">{d}</li>
              ))}
            </div> */}
              </div>
            ) : currentTab == "Risk Mitigation" ? (
              <div className="Analysis-box">
                <h3 className="analysis-report-heading">
                  Analysis Report Results
                </h3>
                <span className="analysis-report-message">
                  The following items addressed in order to reduce the
                  individual’s risk:
                </span>
                <div className="mt-4">
                  {apiData?.riskTableData?.highRiskItems?.map((d, i) => (
                    <li className="analysis-risk-items">{d}</li>
                  ))}
                </div>
              </div>
            ) : (
              <table
                width="100%"
                border="1"
                className={`tablerisk ${
                  currentTab === "Impact" ? "d-none" : ""
                }`}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      Member Risk Profile - {kycDetail?.fullName || "N/A"}
                    </th>
                    {currentTab === "Show All" ? <th></th> : ""}
                    {currentTab === "Show All" ? <th></th> : ""}

                    <th colSpan="3"></th>
                  </tr>
                  <tr className="bg-grey-10">
                    <th>#</th>
                    <th align="left">Risk Factor (RF)</th>
                    {currentTab === "Show All" ||
                    currentTab === "Risk Profile" ||
                    currentTab === "Risks Identified" ? (
                      <th align="left">Risk Factor Rating (RFR) 1-5</th>
                    ) : (
                      ""
                    )}
                    {currentTab === "Show All" ||
                    currentTab === "Risk Score" ? (
                      <th>Risk Weight (RW) % of 1</th>
                    ) : (
                      ""
                    )}
                    {currentTab === "Show All" ||
                    currentTab === "Risk Score" ? (
                      <th>Risk Rating (RR) RFR*RW</th>
                    ) : (
                      ""
                    )}
                    {currentTab === "Show All" ? <th>Value</th> : ""}
                    {currentTab === "Show All" ||
                    currentTab === "Risks Identified" ? (
                      <th>RF Description</th>
                    ) : (
                      ""
                    )}
                    {currentTab === "Show All" ? <th>Data Source</th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {riskArr?.length > 0 &&
                    riskArr?.map((e, idx) => {
                      return (
                        <tr key={e.name}>
                          <td>{++idx}</td>
                          <td>{e.name}</td>
                          {currentTab === "Show All" ||
                          currentTab === "Risk Profile" ||
                          currentTab === "Risks Identified" ? (
                            <td>
                              {(e.riskLevel || "0").toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          ) : (
                            ""
                          )}
                          {currentTab === "Show All" ||
                          currentTab === "Risk Score" ? (
                            <td>
                              {(e.weight || "0").toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          ) : (
                            ""
                          )}
                          {currentTab === "Show All" ||
                          currentTab === "Risk Score" ? (
                            <td>
                              {(
                                Number(e.riskLevel) * Number(e.weight) || "0"
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          ) : (
                            ""
                          )}
                          {currentTab === "Show All" ? (
                            <td>{checkKYCValue(e.name)}</td>
                          ) : (
                            ""
                          )}
                          {currentTab === "Show All" ||
                          currentTab === "Risks Identified" ? (
                            <td>{e.title || "-"}</td>
                          ) : (
                            ""
                          )}
                          {currentTab === "Show All" ? (
                            <td>
                              <div className="d-flex align-items-center justify-content-between">
                                {" "}
                                {checkDataSource(e.name, e.DataSource)}
                                {checkDataSource(e.name, e.DataSource) ===
                                "Default" ? (
                                  <img width={25} src={warning} />
                                ) : null}
                              </div>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <th align="left">Risk Factor Score (out of 100)</th>
                    {currentTab === "Show All" ||
                    currentTab === "Risk Profile" ||
                    currentTab === "Risks Identified" ? (
                      <th align="right">
                        {riskArr
                          .map((item) => item.riskLevel)
                          .reduce((a, b) => {
                            return a + b;
                          }, 0)
                          ?.toFixed(2)}
                      </th>
                    ) : (
                      ""
                    )}
                    {currentTab === "Show All" ||
                    currentTab === "Risk Score" ? (
                      <th align="right">
                        {riskArr
                          .map((item) => item.weight)
                          .reduce((a, b) => {
                            return a + b;
                          }, 0)
                          ?.toFixed(2)}
                      </th>
                    ) : (
                      ""
                    )}
                    {currentTab === "Show All" ||
                    currentTab === "Risk Score" ? (
                      <th>
                        {riskArr
                          .map((item) => item.riskrating)
                          .reduce((a, b) => a + b, 0)
                          ?.toFixed(2)}
                      </th>
                    ) : (
                      ""
                    )}
                    {currentTab === "Show All" ? <th>-</th> : ""}
                    {currentTab === "Show All" ? <td>-</td> : ""}
                    {currentTab === "Show All" ? <td>-</td> : ""}
                  </tr>
                  <tr>
                    <th></th>
                    <th align="left">
                      Account Holder Risk to Bank (adjustment factor x5 i.e.
                      multiply RR*5)
                    </th>
                    <th>
                      {(
                        riskArr
                          .map((item) => item.riskrating)
                          .reduce((a, b) => {
                            return a + b;
                          }, 0) * Number(5)
                      )?.toFixed(2)}
                    </th>
                    {currentTab === "Show All" ? <th colSpan="5"></th> : ""}
                  </tr>
                </tfoot>
              </table>
            )}
            {currentTab === "Show All" && (
              <div className="col-lg-12 p-0 my-4">
                <h2 className="py-3  page-title mb-0">
                  Risk Category - Impact - Probability
                </h2>
              </div>
            )}
            <table
              width="100%"
              border="1"
              className={`tablerisk mt-3 w-100 ${
                currentTab === "Risks Identified" ||
                currentTab === "Risk Profile" ||
                currentTab === "Risk Score"
                  ? "d-none"
                  : ""
              }`}
            >
              <thead>
                <tr className="bg-grey-10">
                  <th className="text-center">Risk Category</th>
                  <th className="text-center">Score</th>
                  <th className="text-center">Impact</th>
                  <th className="text-center">Probability</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="text-center">
                    {riskCategory.riskCategory || "none"}
                  </th>
                  <td className="text-center">
                    {riskCategory.overallRisk?.toFixed(2) || "-"}
                  </td>
                  <td className="text-center">{riskCategory.impact || "-"}</td>
                  <td className="text-center">
                    {riskCategory.probability || "-"}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              width="100%"
              border="1"
              className={`tablerisk mt-3 w-100 ${
                currentTab === "Risks Identified" ||
                currentTab === "Risk Profile" ||
                currentTab === "Risk Score"
                  ? "d-none"
                  : ""
              }`}
            >
              <thead>
                <tr>
                  <th></th>
                  <th colspan="7" className="text-white" bgColor="#0b57d0">
                    Risk Category Table
                  </th>
                </tr>
              </thead>
              <thead>
                <tr className="bg-grey-10">
                  <th style={{ height: "42" }} className="text-center">
                    Risk Category
                  </th>
                  <th className="text-center">Score</th>
                  <th className="text-center">Color Code</th>
                  <th className="text-center">Min Risk Score</th>
                  <th className="text-center">Max Risk Score</th>
                </tr>
              </thead>
              <tbody>
                {riskCategoryTable?.map((d, i) => (
                  <tr>
                    <th className="text-center">{d.category}</th>
                    <td className="text-center">{d.score}</td>
                    <td
                      style={{ background: d.colorCode }}
                      className="text-center"
                    ></td>
                    <td className="text-center">{d.minScore}</td>
                    <td className="text-center">{d.maxScore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table
              className={`RiskImpact tablerisk ${
                currentTab === "Impact" || currentTab === "Show All"
                  ? ""
                  : "d-none"
              }`}
            >
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th colspan="7" className="text-white" bgColor="#0b57d0">
                    Risk Impact & Probability table
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th rowspan="10">
                    <span class="rtl-txt">Impact</span>
                  </th>
                  <th className="text-center">Severe</th>
                  <th className="text-center">5</th>
                  <th className="text-center bg-warning">5</th>
                  <th className="text-center bg-danger">10</th>
                  <th className="text-center bg-danger">15</th>
                  <th className="text-center bg-black text-white">20</th>
                  <th className="text-center bg-black text-white">25</th>
                </tr>
                <tr>
                  <th className="text-center">High</th>
                  <th className="text-center">4</th>
                  <th className="text-center bg-warning">4</th>
                  <th className="text-center" bgColor="orange">
                    8
                  </th>
                  <th className="text-center bg-danger">12</th>
                  <th className="text-center bg-black text-white">16</th>
                  <th className="text-center bg-black text-white">20</th>
                </tr>
                <tr>
                  <th className="text-center">Moderate</th>
                  <th className="text-center">3</th>
                  <th className="text-center bg-success">3</th>
                  <th className="text-center bg-warning">6</th>
                  <th className="text-center" bgColor="orange">
                    9
                  </th>
                  <th className="text-center bg-danger">12</th>
                  <th className="text-center bg-danger">15</th>
                </tr>
                <tr>
                  <th className="text-center">Maginal</th>
                  <th className="text-center">2</th>
                  <th className="text-center bg-success">2</th>
                  <th className="text-center bg-warning">4</th>
                  <th className="text-center bg-warning">6</th>
                  <th className="text-center" bgColor="orange">
                    8
                  </th>
                  <th className="text-center bg-danger">10</th>
                </tr>
                <tr>
                  <th className="text-center">Minimal</th>
                  <th className="text-center">1</th>
                  <th className="text-center bg-success">1</th>
                  <th className="text-center bg-success">2</th>
                  <th className="text-center bg-success">3</th>
                  <th className="text-center bg-warning">4</th>
                  <th className="text-center bg-warning">5</th>
                </tr>
                <tr>
                  <th class="h-fix"></th>
                  <th class="h-fix"></th>
                  <th class="h-fix">Improbable</th>
                  <th class="h-fix">Low</th>
                  <th class="h-fix">moderate</th>
                  <th class="h-fix">Probable</th>
                  <th class="h-fix">Certain</th>
                </tr>
                <tr>
                  <th></th>
                  <th colspan="6" className="text-center" bgColor="yellow">
                    Probability
                  </th>
                </tr>
              </tbody>
            </table>
          </section>
        </>
      ) : (
        <div
          style={{ height: "500px" }}
          className="d-flex justify-content-center align-items-center"
        >
          <BeatLoader color="#0E8DEE" />
        </div>
      )}
    </>
  );
};

export default RiskTable;
